import React from 'react'
import styled from 'styled-components'
import { Flex, Select, getPaletteColor } from 'pcln-design-system'

const AgeFlex = styled(Flex)`
  svg {
    color: ${getPaletteColor('primary.base')};
  }
`

const selectStyle = {
  border: 'none',
  cursor: 'pointer',
  paddingLeft: 16,
  fontSize: 16,
  fontWeight: 700,
  width: 200
}

export default function ChildAgeSelect({
  childAge,
  childIndex,
  updateAges,
  childAgeSelectors
}: {
  childAge?: number
  childIndex: number
  updateAges: (age: number, index: number) => void
  childAgeSelectors?: {
    optionsText: {
      infant?: string
      oneYear?: string
    }
  }
}) {
  const ageText = 'yrs'
  const ageDefaultValue =
    childAge === undefined ? `Add Child ${childIndex + 1}` : childAge
  return (
    <AgeFlex justifyContent="center" my={1}>
      <Select
        defaultValue={ageDefaultValue}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          updateAges(Number(e.target.value), childIndex)
        }}
        name="childAgeSelect"
        style={selectStyle}
        title={`Select age for child ${childIndex + 1}`}
      >
        <option value={-1}>{`Add Child ${childIndex + 1}`}</option>
        <option value={0}>{childAgeSelectors?.optionsText.infant}</option>
        <option value={1}>{childAgeSelectors?.optionsText.oneYear}</option>
        <option value={2}>2 {ageText}</option>
        <option value={3}>3 {ageText}</option>
        <option value={4}>4 {ageText}</option>
        <option value={5}>5 {ageText}</option>
        <option value={6}>6 {ageText}</option>
        <option value={7}>7 {ageText}</option>
        <option value={8}>8 {ageText}</option>
        <option value={9}>9 {ageText}</option>
        <option value={10}>10 {ageText}</option>
        <option value={11}>11 {ageText}</option>
        <option value={12}>12 {ageText}</option>
        <option value={13}>13 {ageText}</option>
        <option value={14}>14 {ageText}</option>
        <option value={15}>15 {ageText}</option>
        <option value={16}>16 {ageText}</option>
        <option value={17}>17 {ageText}</option>
      </Select>
    </AgeFlex>
  )
}
