import { filter, join } from 'ramda'

const pluralizeWord = (word: string) => {
  const ending = word === 'Child' ? 'ren' : 's'
  return word + ending
}

const formatLabel = (count: number, traveler: string) => {
  const travelerLabel = count > 1 ? pluralizeWord(traveler) : traveler
  return `${count} ${travelerLabel}`
}

const renderMenuLabel = ({
  adultCount,
  childrenCount,
  infantCount,
  roomCount,
  showInfantMenuItem,
  showRoomMenuItem
}: {
  adultCount: number
  childrenCount: number
  infantCount: number
  roomCount: number
  showInfantMenuItem: boolean
  showRoomMenuItem: boolean
}) => {
  const adultLabel = adultCount > 0 ? formatLabel(adultCount, 'Adult') : ''
  const childrenLabel =
    childrenCount > 0 ? formatLabel(childrenCount, 'Child') : ''
  const infantLabel =
    showInfantMenuItem && infantCount > 0
      ? formatLabel(infantCount, 'Lap Infant')
      : ''
  const passengerCount = adultCount + childrenCount + infantCount
  const passengerLabel =
    passengerCount > 0 ? formatLabel(passengerCount, 'Passenger') : ''
  const roomsLabel =
    showRoomMenuItem && roomCount > 0 ? formatLabel(roomCount, 'Room') : ''

  let labels
  if (infantCount > 0 && childrenCount > 0) {
    labels = [passengerLabel, roomsLabel]
  } else {
    labels = [adultLabel, childrenLabel, infantLabel, roomsLabel]
  }

  return join(
    ', ',
    filter(label => label !== '', labels)
  )
}

export default renderMenuLabel
