import React from 'react'
import { Box, Layout } from 'pcln-design-system'

type LayoutComponentProps = {
  children: React.ReactNode
  topGap?: boolean
}

export default function LayoutComponentWrapper({
  children,
  topGap = false
}: LayoutComponentProps) {
  return (
    // TODO: Clean the topGap prop after DS discussion
    <Layout variation="100" rowGap="lg">
      {topGap ? <Box /> : null}
      {children}
    </Layout>
  )
}
