import styled from 'styled-components'
import { Button, getPaletteColor } from 'pcln-design-system'

const StyledMenuButton = styled(Button)`
  &[aria-controls='traveler-selection-control-panel'] {
    position: relative;
    height: 56px;
    padding-left: 8px;
    padding-right: 8px;
    &[aria-invalid='true'] {
      border-color: ${getPaletteColor('error.base')};
    }
  }
`

export default StyledMenuButton
