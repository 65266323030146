import React, { type SyntheticEvent } from 'react'
import { IconButton } from 'pcln-design-system'
import { RadioMinus, RadioPlus } from 'pcln-icons'
import { lowerCaseFirstCharacter, removeWhiteSpace } from './utils'

type OperationButtonProp = {
  handleClick: () => void
  iconName: string
  isDisabled: boolean
  label: string
}

export default function OperationButton({
  handleClick,
  iconName,
  isDisabled,
  label
}: OperationButtonProp) {
  const modifiedLabel = lowerCaseFirstCharacter(removeWhiteSpace(label))
  const iconButtonId = `traveler-selection-${modifiedLabel}-${iconName}-button`
  const disabled = isDisabled ? ' - disabled' : ''
  const ariaLabel =
    iconName === 'radioMinus'
      ? `Decrease ${label}${disabled}`
      : `Increase ${label}${disabled}`

  const RadioIcon = iconName === 'radioMinus' ? RadioMinus : RadioPlus
  return (
    <IconButton
      aria-label={ariaLabel}
      aria-controls={`${modifiedLabel}-number`}
      id={iconButtonId}
      data-testid={iconButtonId}
      icon={<RadioIcon size={24} color={isDisabled ? 'border' : 'primary'} />}
      onClick={(e: SyntheticEvent) => {
        e.preventDefault()
        if (!isDisabled) {
          handleClick()
        }
      }}
      name={iconName}
      size="medium"
      style={{ cursor: 'pointer' }}
    />
  )
}
