import React from 'react'
import ChildAgeSelect from './ChildAgeSelect'
import { SetTravelers } from './types'

export default function ChildAgeSelectors({
  ages,
  childrenCount,
  setTravelers,
  childAgeSelectors
}: {
  ages: number[]
  childrenCount: number
  setTravelers: SetTravelers
  childAgeSelectors?: {
    optionsText: {
      infant?: string
      oneYear?: string
    }
  }
}) {
  const updateAges = (age: number, index: number) => {
    const newAges = ages.slice()
    newAges[index] = age
    setTravelers({ type: 'UPDATE_AGES', ages: newAges })
  }

  const childSelectRows = []
  for (let i = 0; i < childrenCount; i++) {
    childSelectRows.push(
      <ChildAgeSelect
        childIndex={i}
        childAge={ages[i]}
        key={i}
        updateAges={updateAges}
        childAgeSelectors={childAgeSelectors}
      />
    )
  }
  return <>{childSelectRows}</>
}
