import { type ISearchFormType } from '@pcln/contentful-components'
import { hasContentTypeId } from '@/shared-utils/content-model-type-guards'
import useContentfulVariation from './useContentfulVariation'

function isISearchFormType(content: unknown): content is ISearchFormType {
  return hasContentTypeId(content) && content.contentTypeId === 'optIn'
}

export default function useContentfulSearchFormHeadlines(
  name: string,
  shouldFireImpression = true
) {
  const searchForm = useContentfulVariation(
    name,
    isISearchFormType,
    shouldFireImpression
  )
  return searchForm ?? null
}
