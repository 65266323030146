import React, { type SyntheticEvent } from 'react'
import { Tooltip } from 'pcln-design-system'
import { User as UserIcon, Warning as WarningIcon } from 'pcln-icons'
import StyledMenuButton from './Elements.styled'

type MenuProp = {
  isPanelOpen: boolean
  hasError: boolean
  label: string
  disabled: boolean
  toggleMenu: (open: boolean) => (e: SyntheticEvent) => void
  error?: string
}

const errorTooltipBaseStyles = {
  bottom: true,
  color: 'error',
  right: true
}

function IconLeft() {
  return <UserIcon color="primary" size={24} mr={2} />
}

function IconRight() {
  return <WarningIcon color="error" size={20} ml="auto" />
}

export default function Menu({
  isPanelOpen = false,
  hasError = false,
  label = '',
  toggleMenu,
  disabled,
  error
}: MenuProp) {
  const errorMessageId = `ts-error-id-${new Date().getTime()}`
  const showError = hasError && !isPanelOpen
  return (
    <>
      <StyledMenuButton
        disabled={disabled}
        borderRadius="lg"
        variation="input"
        type="button"
        color="text.lightest"
        width={1}
        onClick={toggleMenu(isPanelOpen)}
        aria-label="traveler-selection-control-panel"
        aria-expanded={isPanelOpen}
        aria-controls="traveler-selection-control-panel"
        aria-invalid={showError ? 'true' : 'false'}
        aria-errormessage={showError ? errorMessageId : undefined}
        IconLeft={IconLeft}
        IconRight={showError ? IconRight : undefined}
        flexProps={{ justifyContent: 'left' }}
      >
        {label}
      </StyledMenuButton>
      {showError && (
        <Tooltip
          {...errorTooltipBaseStyles}
          id={errorMessageId}
          zIndex={2}
          role="alert"
        >
          {error}
        </Tooltip>
      )}
    </>
  )
}
