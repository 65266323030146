import React, {
  useState,
  useEffect,
  useReducer,
  type SyntheticEvent
} from 'react'
import styled from 'styled-components'
import { Button, Card, Container, Text } from 'pcln-design-system'
import { Popover } from '@/components/Popover'
import ClickOutsideWrapper from '@/components/ClickOutsideWrapper'
import useBootstrapData from '@/hooks/useBootstrapData'
import { OldTravelerState } from '@/types'
import Menu from './Menu'
import MenuItemsPanel from './MenuItemsPanel'
import ChildAgeSelectors from './ChildAgeSelectors'
import renderMenuLabel from './renderMenuLabel'
import { TRAVELER_SELECTION_CONTAINER_MAX_WIDTH } from './constants'
import { TravelerKey, TravelerSelectionType } from './types'

const MenuCard = styled(Card)`
  user-select: none;
  z-index: 4;
  border-color: transparent;
`

const StyledButton = styled(Button)`
  display: block;
  margin: 0 auto 16px auto;
  width: 85%;
`

const StyledPopover = styled(Popover)<{ disabled: boolean }>`
  pointer-events: ${props => (props.disabled ? 'none' : undefined)};
`

function travelerReducer(
  state: OldTravelerState,
  action: {
    type: string
    ages?: number[]
    travelerKey?: TravelerKey
  }
) {
  const { ages = [], travelerKey } = action

  switch (action.type) {
    case 'UPDATE_AGES': {
      return { ...state, ages }
    }
    case 'ADD_TRAVELER': {
      const currentCount = state[travelerKey as TravelerKey] ?? 0
      return {
        ...state,
        [travelerKey as TravelerKey]: currentCount + 1
      }
    }
    case 'REMOVE_TRAVELER': {
      const currentCount = state[travelerKey as TravelerKey] ?? 0
      const newAges =
        travelerKey !== 'adultCount' && Array.isArray(state.ages)
          ? state.ages.slice(0, state.ages.length - 1)
          : state.ages

      return {
        ...state,
        ages: newAges,
        [travelerKey as TravelerKey]: currentCount - 1
      }
    }
    default:
      return state
  }
}

export default function TravelerSelection({
  adultCount = 0,
  childrenAges = [],
  childrenCount = 0,
  disableShadowState,
  disclaimer,
  error,
  hotelPlannerLinkAttrs,
  infantCount = 0,
  maxAdults,
  maxChildren,
  maxRooms,
  onDoneButtonClick,
  roomCount = 0,
  setValue,
  showChildAgeSelectors = false,
  showInfantMenuItem = false,
  showRoomMenuItem = false,
  showSubLabel = false,
  disabled = false,
  isHotelForm = false,
  childAgeSelectors
}: TravelerSelectionType) {
  const [isPanelOpen, setIsPanelOpen] = useState(false)
  const [travelers, setTravelers] = useReducer(travelerReducer, {
    adultCount,
    childrenCount,
    infantCount,
    roomCount,
    ages: childrenAges
  })
  const { isIOS } = useBootstrapData()
  useEffect(() => {
    if (isHotelForm) {
      setValue('travelers', travelers, { shouldDirty: true })
    }
  }, [travelers, setValue, isHotelForm])

  useEffect(() => {
    setValue('adultCount', travelers.adultCount, { shouldDirty: true })
  }, [travelers.adultCount, setValue])

  useEffect(() => {
    setValue('childrenAges', travelers.ages, { shouldDirty: true })
  }, [travelers.ages, setValue])

  useEffect(() => {
    setValue('childrenCount', travelers.childrenCount, { shouldDirty: true })
  }, [travelers.childrenCount, setValue])

  useEffect(() => {
    setValue('infantCount', travelers.infantCount, { shouldDirty: true })
  }, [travelers.infantCount, setValue])

  useEffect(() => {
    setValue('roomCount', travelers.roomCount, { shouldDirty: true })
  }, [travelers.roomCount, setValue])

  const toggleMenu = (open: boolean) => (e: SyntheticEvent) => {
    e.preventDefault()
    if (open) {
      disableShadowState()
    }
    setIsPanelOpen(!open)
  }
  return (
    <ClickOutsideWrapper setIsPanelOpen={() => disableShadowState()}>
      <Container
        data-testid="traveler-selection"
        maxWidth={TRAVELER_SELECTION_CONTAINER_MAX_WIDTH}
      >
        <StyledPopover
          top={isPanelOpen ? '8px' : undefined}
          p={isPanelOpen ? [3, 0] : undefined}
          disabled={disabled}
          color="background.lightest"
          data-traveler-selection="true"
          key="traveler-selection"
          borderRadius={['2xl', null, 'lg']}
          onDismiss={() => {
            setIsPanelOpen(!isPanelOpen)
          }}
          open={isPanelOpen}
          title="Traveler Selection"
          width={1}
          zIndex={isPanelOpen ? 2 : 0}
        >
          <Menu
            disabled={disabled}
            hasError={error.length > 0}
            isPanelOpen={isPanelOpen}
            label={renderMenuLabel({
              ...travelers,
              showInfantMenuItem,
              showRoomMenuItem,
              infantCount: travelers.infantCount || 0
            })}
            toggleMenu={toggleMenu}
            error={error}
          />
          {isPanelOpen && (
            <Popover.Body
              mt={[0, null, 2]}
              mr={[0, null, -3, 0]}
              openState={isPanelOpen}
            >
              <MenuCard
                mt={isIOS ? 2 : 0}
                boxShadowSize={[null, null, 'overlay-lg']}
                color="background.lightest"
                data-testid="traveler-selection-control-panel"
                id="traveler-selection-control-panel"
                title="Traveler Selection Menu"
                width={[1, null, null, null, 280]}
                borderRadius={['lg', null, 'xl']}
              >
                <MenuItemsPanel
                  hotelPlannerLinkAttrs={hotelPlannerLinkAttrs}
                  maxAdults={maxAdults}
                  maxChildren={maxChildren}
                  maxRooms={maxRooms}
                  setTravelers={setTravelers}
                  showInfantMenuItem={showInfantMenuItem}
                  showRoomMenuItem={showRoomMenuItem}
                  showSubLabel={showSubLabel}
                  travelers={travelers}
                />
                {showChildAgeSelectors && (
                  <ChildAgeSelectors
                    ages={travelers.ages}
                    childrenCount={travelers.childrenCount}
                    setTravelers={setTravelers}
                    childAgeSelectors={childAgeSelectors}
                  />
                )}
                <StyledButton
                  onClick={() => {
                    setIsPanelOpen(false)
                    onDoneButtonClick()
                  }}
                  title="traveler-selection-done-button"
                  type="button"
                >
                  Done
                </StyledButton>
                {disclaimer && (
                  <Text fontSize={0} m={3}>
                    {disclaimer}
                  </Text>
                )}
              </MenuCard>
            </Popover.Body>
          )}
        </StyledPopover>
      </Container>
    </ClickOutsideWrapper>
  )
}
