import React, { useRef, useEffect } from 'react'

function useOutsideWrapper(
  ref: React.MutableRefObject<HTMLDivElement | null>,
  setIsPanelOpen: (value: boolean) => void
) {
  useEffect(() => {
    /**
     * Closes panel if clicked on outside
     */
    function handleClickOutside(event: MouseEvent) {
      if (
        ref.current &&
        event.target instanceof Element &&
        !ref.current.contains(event.target)
      ) {
        setIsPanelOpen(false)
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, setIsPanelOpen])
}

function OutsideWrapper({
  children,
  setIsPanelOpen
}: {
  children: JSX.Element
  setIsPanelOpen: (value: boolean) => void
}) {
  const wrapperRef = useRef<HTMLDivElement>(null)
  useOutsideWrapper(wrapperRef, setIsPanelOpen)

  return <div ref={wrapperRef}>{children}</div>
}

export default OutsideWrapper
