import React from 'react'
import { Flex, Text } from 'pcln-design-system'
import OperationButton from './OperationButton'
import { SetTravelers, TravelerKey } from './types'
import { lowerCaseFirstCharacter, removeWhiteSpace } from './utils'

export default function MenuItem({
  isMinusIconButtonDisabled,
  isPlusIconButtonDisabled,
  label,
  setTravelers,
  subLabel,
  showSubLabel = false,
  travelerCount = 0,
  travelerKey
}: {
  isMinusIconButtonDisabled: boolean
  isPlusIconButtonDisabled: boolean
  label: string
  setTravelers: SetTravelers
  showSubLabel: boolean
  subLabel?: string
  travelerCount: number
  travelerKey: TravelerKey
}) {
  const TextSpan = Text.span

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      px={3}
      style={{ height: 52 }}
    >
      <TextSpan fontSize={2}>
        {label}
        {showSubLabel && (
          <TextSpan color="gray" fontSize={1} ml={1}>
            {subLabel}
          </TextSpan>
        )}
      </TextSpan>
      <Flex>
        <OperationButton
          handleClick={() =>
            setTravelers({ type: 'REMOVE_TRAVELER', travelerKey })
          }
          iconName="radioMinus"
          isDisabled={isMinusIconButtonDisabled}
          label={label}
        />
        <Text
          aria-label={`${label} count`}
          aria-live="polite"
          aria-atomic="true"
          fontSize={2}
          id={`${lowerCaseFirstCharacter(removeWhiteSpace(label))}-number`}
          px={2}
          style={{ width: '1.6em' }}
          textAlign="center"
        >
          {travelerCount}
        </Text>
        <OperationButton
          handleClick={() =>
            setTravelers({ type: 'ADD_TRAVELER', travelerKey })
          }
          iconName="radioPlus"
          label={label}
          isDisabled={isPlusIconButtonDisabled}
        />
      </Flex>
    </Flex>
  )
}
