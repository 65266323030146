import React from 'react'
import styled from 'styled-components'
import { Box, Divider, Link, Text } from 'pcln-design-system'
import { ArrowRight as ArrowRightIcon } from 'pcln-icons'
import { OldTravelerState } from '@/types'
import MenuItem from './MenuItem'
import {
  labels,
  MAX_PASSENGERS,
  MAX_ROOMS,
  MIN_ADULTS,
  MIN_CHILDREN,
  MIN_ROOMS,
  subLabels
} from './constants'
import { SetTravelers, TravelerKey } from './types'

type MenuItemsPanelType = {
  hotelPlannerLinkAttrs?: { href: string; target: string }
  maxAdults?: number
  maxChildren?: number
  maxRooms?: number
  setTravelers: SetTravelers
  showInfantMenuItem: boolean
  showRoomMenuItem: boolean
  showSubLabel: boolean
  travelers: OldTravelerState
}

const LinkContainer = styled(Link)`
  display: flex;
`

export default function MenuItemsPanel({
  hotelPlannerLinkAttrs,
  maxAdults,
  maxChildren,
  maxRooms,
  setTravelers,
  showInfantMenuItem,
  showRoomMenuItem,
  showSubLabel,
  travelers
}: MenuItemsPanelType) {
  const { adultCount, childrenCount, infantCount = 0, roomCount } = travelers
  const metas = []
  const passengers = adultCount + childrenCount + infantCount
  const isMaxPassengers = passengers >= MAX_PASSENGERS
  const isMaxAdults = maxAdults ? adultCount >= maxAdults : isMaxPassengers
  const isMaxChildren = maxChildren
    ? childrenCount >= maxChildren
    : isMaxPassengers
  const isMaxRoom = maxRooms || MAX_ROOMS

  if (showRoomMenuItem) {
    metas.push({
      isMinusIconButtonDisabled: roomCount <= MIN_ROOMS,
      isPlusIconButtonDisabled: roomCount >= isMaxRoom,
      label: labels.ROOMS,
      subLabel: subLabels.ROOMS,
      travelerCount: roomCount,
      travelerKey: 'roomCount'
    })
  }

  metas.push({
    isMinusIconButtonDisabled: adultCount <= MIN_ADULTS,
    isPlusIconButtonDisabled: isMaxAdults,
    label: labels.ADULTS,
    subLabel: subLabels.ADULTS,
    travelerCount: adultCount,
    travelerKey: 'adultCount'
  })

  metas.push({
    isMinusIconButtonDisabled: childrenCount <= MIN_CHILDREN,
    isPlusIconButtonDisabled: isMaxChildren || childrenCount >= 6,
    label: labels.CHILDREN,
    subLabel: subLabels.CHILDREN,
    travelerCount: childrenCount,
    travelerKey: 'childrenCount'
  })

  if (showInfantMenuItem) {
    metas.push({
      isMinusIconButtonDisabled: infantCount <= MIN_CHILDREN,
      isPlusIconButtonDisabled: infantCount >= adultCount || isMaxPassengers,
      label: labels.INFANTS,
      subLabel: subLabels.INFANTS,
      travelerCount: infantCount,
      travelerKey: 'infantCount'
    })
  }

  const MenuItems = metas.map(
    (
      {
        label,
        subLabel,
        travelerCount,
        isPlusIconButtonDisabled,
        isMinusIconButtonDisabled,
        travelerKey
      },
      index
    ) => {
      const islastItem = index === metas.length - 1
      return (
        <div key={label}>
          <MenuItem
            isMinusIconButtonDisabled={isMinusIconButtonDisabled}
            isPlusIconButtonDisabled={isPlusIconButtonDisabled}
            label={label}
            setTravelers={setTravelers}
            showSubLabel={showSubLabel}
            subLabel={subLabel}
            travelerCount={travelerCount}
            travelerKey={travelerKey as TravelerKey}
          />
          {label === labels.ROOMS && travelerCount > MAX_PASSENGERS ? (
            <Box mb={2} px={3}>
              <Text color="text.light" fontSize={0} mb={2}>
                Need 10 or more rooms? Special group rates available with our
                hotel partner, hotelplanner.com
              </Text>
              <LinkContainer {...hotelPlannerLinkAttrs}>
                <Text fontSize={0}>10 Rooms or More</Text>
                <ArrowRightIcon ml={1} size={18} />
              </LinkContainer>
            </Box>
          ) : null}
          {!islastItem && <Divider borderColor="lightGray" mx={3} my={0} />}
        </div>
      )
    }
  )

  return <>{MenuItems}</>
}
