import {
  getVariantContent,
  type IVariationCollectionType,
  useContentfulWithPredicate,
  type VariationItem
} from '@pcln/contentful-components'
import useSeti from '@/hooks/useSeti'
import {
  isExperimentOfSpecifiedType,
  isIVariationCollectionType
} from '@/shared-utils/content-model-type-guards'

export default function useContentfulVariation<T extends VariationItem>(
  fieldName: string,
  predicate: (content: unknown) => content is T,
  shouldFireImpression = true
): T | undefined {
  const content = useContentfulWithPredicate(
    fieldName,
    function newPredicate(data: unknown): data is T | IVariationCollectionType {
      return predicate(data) || isIVariationCollectionType(data)
    }
  )
  const isExperiment = isIVariationCollectionType(content)
  const setiControl = useSeti(
    isExperiment ? content.experimentName : '',
    shouldFireImpression
  )
  if (!isExperiment) {
    return content
  }
  if (isExperimentOfSpecifiedType(content, predicate)) {
    return getVariantContent(setiControl, content)
  }
  return undefined
}
